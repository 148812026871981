import React from 'react';

const BeatStories = () => {
  return (
    <div id="beat-stories" className="p-6 text-gray-800 bg-gray-900">
      {/* Conteneur principal avec disposition flex */}
      <div className="flex flex-col items-center space-y-6">
        {/* Section texte */}
        <div className="flex-1 text-center">
          {/* Titre principal */}
          <h1 className="text-4xl font-bold my-6 text-white">
            Beat Stories : L’univers musical en moins de deux minutes
          </h1>

          {/* Introduction */}
          <p className="text-lg leading-relaxed text-orange-500">
            <strong className='text-white'>Beat Stories</strong> est un concept court et percutant qui explore l’univers de la musique de manière 
            éducative et divertissante. Chaque épisode, d’une durée de moins de deux minutes, vous emmène à la découverte 
            des multiples facettes de la musique, des icônes légendaires aux dernières tendances.
          </p>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Un format concis et captivant</h2>
          <div className="mt-4 space-y-2 text-orange-500">
            <div>
              <strong className='text-white'>Des thématiques variées</strong> : Découvrez l’histoire des genres musicaux, les artistes influents et 
              les morceaux emblématiques qui ont marqué l’industrie.
            </div>
            <div>
              <strong className='text-white'>L’actualité musicale</strong> : Restez informé des dernières tendances, des sorties d’albums et des événements 
              marquants de la scène musicale.
            </div>
            <div>
              <strong className='text-white'>Une approche éducative</strong> : Chaque épisode enrichit votre culture musicale tout en restant accessible 
              et dynamique.
            </div>
          </div>

          {/* Sous-titre */}
          <h2 className="text-2xl font-semibold mt-8 text-white">Pourquoi suivre Beat Stories ?</h2>
          <div className="mt-4 space-y-2 text-orange-500">
            <div>
              <strong className='text-white'>Une immersion rapide</strong> : En moins de deux minutes, plongez dans un contenu riche et varié, idéal 
              pour les passionnés de musique pressés.
            </div>
            <div>
              <strong className='text-white'>Un mélange de passé et de présent</strong> : De l’histoire de la musique aux nouveautés incontournables, 
              Beat Stories relie les époques et les influences.
            </div>
            <div>
              <strong className='text-white'>Accessible à tous</strong> : Que vous soyez novice ou expert, ces capsules courtes et engageantes 
              s’adressent à toutes les générations.
            </div>
          </div>
        </div>

        {/* Vidéo */}
        <div className="flex-shrink-0">
          <video
            src="/IMG_1676.mp4"
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            className="w-full max-w-xs lg:max-w-md object-cover rounded-lg mx-auto"
          />
        </div>
      </div>

      {/* Conclusion */}
      <p className="mt-8 text-lg leading-relaxed text-justify text-orange-500 text-center">
        Rejoignez <strong className='text-white'>Beat Stories</strong> pour une dose quotidienne de musique, à consommer en quelques instants mais 
        à savourer longtemps. Une manière rapide et fun de vivre et comprendre la musique !
      </p>
    </div>
  );
};

export default BeatStories;