import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

const Participate = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    age: '',
    insta: '',
    genre_musical: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const emailData = {
      to_email: process.env.REACT_APP_EMAILJS_TO_EMAIL,
      nom: formData.nom,
      prenom: formData.prenom,
      age: formData.age,
      insta: formData.insta,
      genre_musical: formData.genre_musical
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      emailData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setIsLoading(false);
      setIsSubmitted(true);
    })
    .catch((error) => {
      console.log('FAILED...', error);
      setIsLoading(false);
    });
  };

  return (
    <div className="p-6 bg-gray-900 text-gray-800 min-h-screen flex items-center justify-center">
      {isSubmitted ? (
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
          <h2 className="text-2xl font-bold text-white mb-6">Merci pour votre participation !</h2>
          <p className="text-lg text-white">Votre participation a bien été envoyée.</p>
          <Link to="/live-sessions">
            <button className="p-2 bg-orange-500 text-white rounded hover:bg-orange-400">
              Retourner sur la page Live Sessions
            </button>
          </Link>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-white mb-6 text-center">Participer à un Live Session</h2>
          
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="nom">Nom</label>
            <input
              type="text"
              id="nom"
              name="nom"
              value={formData.nom}
              onChange={handleChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="prenom">Prénom</label>
            <input
              type="text"
              id="prenom"
              name="prenom"
              value={formData.prenom}
              onChange={handleChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="age">Âge</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="insta">Identifiant Instagram</label>
            <input
              type="text"
              id="insta"
              name="insta"
              value={formData.insta}
              onChange={handleChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="genre_musical">Genre musical favori</label>
            <input
              type="text"
              id="genre_musical"
              name="genre_musical"
              value={formData.genre_musical}
              onChange={handleChange}
              className="w-full p-2 rounded bg-gray-700 text-white"
              required
            />
          </div>

          <button type="submit" className="w-full p-2 bg-orange-500 text-white rounded hover:bg-orange-400">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-orange-500 h-6 w-6"></div>
                <span className="ml-2">Chargement...</span>
              </div>
            ) : (
              'Envoyer'
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default Participate;